import React from 'react';
import Rgm from '../../components/Rgm';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { parsePhoneNumber } from '../../helpers/validators';

function Map() {
  const {
    userState: { clinics, appInfo, isAuthenticated },
  } = useUserStateDispatch();

  const d = (clinics || []).find(
    (item) => item?.coordinates != null && item?.coordinates !== '',
  );

  const [lat, lng] =
    d != null
      ? d.coordinates.split(', ')
      : ['55.751244', '37.618423']; // moscow coordinates;

  const defaultMapZoom = appInfo.mapsZoomDefault ?? 10;
  const options = {
    zoom: defaultMapZoom,
    center: {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    },
    gestureHandling: 'greedy',
    clickableIcons: false,
    name: 'cntr',
    fullscreenControl: false,
  };

  const data = !isEmpty(clinics)
    ? clinics.map((item) => {
        if (item?.phone == null) return item;

        const phoneNumber = parsePhoneNumber()(item.phone, {
          defaultCountry: appInfo.countryCode,
          extract: false,
        });
        item.phone = phoneNumber?.formatNational();
        return item;
      })
    : [];

  //  console.log('clinics==', data, 'lat, lng', lat, lng);
  return (
    <Rgm
      data={data}
      options={options}
      mapsKey={appInfo.mapsKey}
      isAuthenticated={isAuthenticated}
    />
  );
}

export default Map;
