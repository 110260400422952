import md5 from 'md5';
import custom from '../custom';
import isEmpty from '.';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/mobile';
import { parsePhoneNumber } from './validators';

export const defPlaceCalypsoForm = (
  appInfo,
  calypsoData,
  structure,
  menu = 'tax_deduction',
) => {
  if (isEmpty(calypsoData) || appInfo.authMenuItems.includes(menu))
    return null;

  const structureItem = structure.find((item) => item.link === menu);
  if (isEmpty(structureItem)) return null;

  const calypsoForm = calypsoData.find(
    (item) => item.formUUID === structureItem.formUUID,
  );
  //
  if (calypsoForm.control === 'menu') {
    structureItem.hasMainMenu = true;
    appInfo.authMenuItems = [...appInfo.authMenuItems, menu];
  }

  if (calypsoForm.control === 'profile')
    structureItem.hasLeftMenu = true;
  structureItem.title = calypsoForm.title;

  structure = structure.filter((item) => item.link !== menu);

  structure.push(structureItem);

  console.log('appInfo.authMenuItems', appInfo.authMenuItems);
};

export const toUpperCaseFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const toLowerCaseFirstLetter = (str) => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const transformKeysInData = (data, transformFunc) => {
  if (data === null) return null;

  if (Array.isArray(data))
    return data.map((item) =>
      transformKeysInData(item, transformFunc),
    );

  if (typeof data === 'object')
    return Object.keys(data).reduce((result, key) => {
      result[transformFunc(key)] = transformKeysInData(
        data[key],
        transformFunc,
      );
      return result;
    }, {});

  return data;
};

export const generateSign = (data, secretKey) => {
  const params = Object.keys(data)
    .sort()
    .filter((key) => key !== 'authToken')
    .map((key) => data[key])
    .filter(
      (value) => typeof value !== 'object' && value !== undefined,
    )
    .map((value) =>
      typeof value === 'boolean'
        ? toUpperCaseFirstLetter(value.toString())
        : value,
    );

  return md5(`${params.join('')}${secretKey}`).toString();
};

export const passMd5 = (pass) => md5(pass);

export const cleanPhoneValue = (value) => {
  if (value == null) return '';
  // удаляем разрешенные символы
  const cleanValue = value.replace(/[\s+()-]/gi, '');
  const numbers = cleanValue.replace(/[^\d]/gi, '');
  return numbers;
};
export const formarIncompletePhone = (value, countryCode = 'RU') => {
  let phoneFixed = value;
  if (
    countryCode === 'RU' &&
    phoneFixed.substring(0, 1) !== '+' &&
    phoneFixed.substring(0, 1) !== '7' &&
    phoneFixed.substring(0, 1) !== '8'
  )
    phoneFixed =
      '+7 ' + formatIncompletePhoneNumber(phoneFixed, countryCode);
  else if (countryCode === 'RU' && phoneFixed.substring(0, 1) === '7')
    phoneFixed =
      '+7 ' +
      formatIncompletePhoneNumber(phoneFixed, countryCode).substring(
        1,
      );
  else phoneFixed = formatIncompletePhoneNumber(value, countryCode);

  return phoneFixed;
};

export const formatPhoneString = (value, countryCode = 'RU') => {
  const phoneNumber = parsePhoneNumber(countryCode)(value, {
    defaultCountry: countryCode,
    extract: false,
  });

  if (!phoneNumber?.isValid()) return value;

  let phoneFixed = value;

  switch (phoneNumber.country) {
    case 'RU':
      phoneFixed = '+7' + phoneNumber.formatNational().substring(1);
      break;
    default:
      phoneFixed = phoneNumber.formatInternational();
  }
  return phoneFixed;
};

export const getParam = (param) => {
  const QueryString = window.location.search;
  const urlParams = new URLSearchParams(QueryString);
  return urlParams.get(param);
};

export function appInfoInit(data) {
  const appInfo = {
    ...custom,
    ...data,
  };
  const addRemove = (key, val) => {
    if (
      appInfo[key] != null &&
      appInfo[key] &&
      appInfo.authMenuItems[val] == null
    ) {
      appInfo.authMenuItems.push(val);
    }
    if (
      appInfo[key] != null &&
      !appInfo[key] &&
      appInfo.authMenuItems[val] !== null
    ) {
      appInfo.authMenuItems = appInfo.authMenuItems.filter(
        (e) => e !== val,
      );
    }
    appInfo.authMenuItems = [...new Set(appInfo.authMenuItems)];

    return appInfo;
  };
  appInfo.chatServerAddress = appInfo?.chatServerAddress
    ? `${appInfo.chatServerAddress.replace(/\/+$/g, '')}/` // eslint-disable-next-line no-undef
    : `https://${process.env.REACT_APP_HOST_CHAT}/`;

  const chatDomen = new URL(appInfo.chatServerAddress);

  const chatApi = {
    chatBaseURLApi: `https://${chatDomen.host}${
      chatDomen.pathname || ''
    }`,
    chatWsUrl: `wss://${chatDomen.host}`,
    chatWsPath: `${chatDomen.pathname || '/'}socket.io`,
  };

  // console.log(
  //   '------ chatDomen',
  //   chatDomen,
  //   appInfo?.chatServerAddress,
  // );
  return {
    ...addRemove('showInvoices', 'invoices'),
    ...addRemove('isDirectionsEnabled', 'directions'),
    ...addRemove('isPrescribedDrugsEnabled', 'drugs'),
    // showFastButtonsOnMainPage: true,
    // showLanguageSwitch: true,

    timeSlotsCacheDepth: appInfo?.timeSlotsCacheDepth
      ? parseInt(appInfo.timeSlotsCacheDepth) - 1
      : 30,
    ...chatApi,
  };
}
