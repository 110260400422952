import React from 'react';
import {
  AppBar,
  Divider,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Link } from 'react-router-dom';

import { NotificationsProvider } from '../../context/NotificationsContext';
// components
import UsersMenu from './UsersMenu';
import UsersLanguageMenu from './UsersLanguageMenu';
import UsersNonifications from './UsersNonifications';
import UsersNewRealise from './UsersNewRealise';
import { useUserStateDispatch } from '../../context/UserContext';
import UsersSign from './UsersSign';
import СlinicWebsiteButtonHerro from './СlinicWebsiteButtonHerro';
import custom from '../../custom';

const styles = (theme) => ({
  sideBar: {
    width: '100%',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appContainer: {
    maxWidth: 1280,
    padding: 0,
    margin: '0 auto',
    width: '100%',
    boxSizing: 'border-box',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  logo: {
    // width: 148,
    // height: 54,
    marginLeft: 15,
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      height: 38,
      maxWidth: 240,
      marginLeft: 5,
      marginTop: 7,
    },
  },

  toolbar: {
    padding: 0,
    minHeight: 86,
    [theme.breakpoints.down('sm')]: {
      minHeight: 71,
    },
  },
  divider: {
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(3),

    backgroundColor: theme.palette.grey[100],
  },

  grow: {
    flexGrow: 1,
  },
});

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Header = ({ classes }) => {
  const {
    userState: { isAuthenticated, appInfo },
  } = useUserStateDispatch();

  const LogoHeader = custom.LogoHeader;
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  return (
    <AppBar className={classes.sideBar}>
      <div className={classes.appContainer}>
        <Toolbar className={classes.toolbar}>
          <Link to="/">
            <LogoHeader className={classes.logo} />
          </Link>
          {appInfo.showLanguageSwitch && (
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
          )}
          {appInfo.showLanguageSwitch && <UsersLanguageMenu />}
          {appInfo.clinicWebsiteUrl && !isMobile && (
            <СlinicWebsiteButtonHerro />
          )}

          <div className={classes.grow} />
          <UsersNewRealise />
          {isAuthenticated ? (
            <>
              <NotificationsProvider>
                <UsersNonifications />
              </NotificationsProvider>

              <UsersMenu />
            </>
          ) : (
            <UsersSign />
          )}
        </Toolbar>
      </div>
    </AppBar>
  );
};

export default withStyles(styles)(Header);
