import axios from 'axios';
import jwt_decode from 'jwt-decode';
import config from '../config';
import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';
import {
  appInfoInit,
  cleanPhoneValue,
  generateSign,
  getParam,
} from '../helpers/utils';
import { isValidEmail, isValidPhone } from '../helpers/validators';
import { getBase64 } from '../helpers/base64';
import dayjs from 'dayjs';

export const accountRole = {
  unknown: 0,
  patient: 1,
  agent: 2,
  doctor: 3,
  operator: 4,
};
export const savedEntites = [
  'clinics',
  'specs',
  'promoActions',
  'mmkRecordTypes',
  'notificationsTypes',
  'appInfo',
  'calypsoData',
];

const nameAnonymous = (isPets) => (isPets ? 'Владелец' : 'Anonymous');
export async function getQrKey(setData) {
  try {
    const { data } = await httpClient.post(api.url('GetQrKey'));
    setData({ qrLoaded: true, ...data });
  } catch (err) {
    console.log('Err getQrKey ', err);
  }
}

export async function getQrState(
  key,
  dispatch,
  lang,
  navigate,
  setError,
  setIsLoading,
  isAnonymousChildrenEnabled = false,
) {
  try {
    const { data } = await httpClient.get(api.url('GetQrState'), {
      key,
      isIssuer: false,
    });
    if (data?.status === 'success') {
      localStorage.setItem('authToken', data?.authToken);
      localStorage.setItem('refreshToken', data?.refreshToken);
      setIsLoading(true);
      loginUserData({
        dispatch,
        lang,
        navigate,
        callFromLogin: true,
        setError,
        setIsLoading,
        isAnonymousChildrenEnabled,
      });
    }
  } catch (err) {
    setIsLoading(false);
    setError(getError(err));
    console.log('Err getQrKey ', err);
  }
}

export async function getVersion(setVersion, old) {
  const path = config.nodeEnv === 'development' ? '' : 'static/js';
  const url = `${path}/version.json`;
  try {
    const { data } = await axios.get(
      `${url}?time=${dayjs().format('YYYYMMDD-HH:mm:ss:SSS')}`,
    );

    setVersion({ old, new: data.hash });
  } catch (err) {
    console.log('Err version ', err);
  }
}

export async function showcaseInfo(
  reqData,
  setCaseInfo,
  setIsLoading,
  lang,
) {
  setIsLoading(true);
  await httpClient
    .get(
      api.url('ShowcaseInfo'),
      {
        ...reqData,
      },
      {
        'app-сode': config.APP_CODE,
        'lang-code': config.langCode(lang),
      },
    )
    .then(async ({ data }) => {
      console.log('Console log  ');
      setIsLoading(false);
      setCaseInfo(data);
    })
    .catch(async (err) => {
      console.log('Err ShowcaseInfo ', getError(err));
      setIsLoading(false);
      //await setError(getError(err));
    });
}
export async function brandLogoImage(brandId, setBrand) {
  await httpClient
    .get(api.url('GetBrandLogoImage'), {
      brandId,
    })
    .then(async ({ data }) => {
      console.log('GetBrandLogoImage', data);
      setBrand(data);
    })
    .catch(async (err) => {
      console.log('Err GetBrandLogoImage ', getError(err));
    });
}

export async function agreementText(
  setAgreement,
  setIsLoading,
  //  setError,
) {
  setIsLoading(true);
  await httpClient
    .get(
      api.url('GetAppLicenseAgreementText'),
      {
        platform: 'web',
      },
      { 'app-сode': config.APP_CODE },
    )
    .then(({ data }) => {
      setIsLoading(false);
      setAgreement(data?.text);
    })
    .catch((err) => {
      console.log('Err GetAppLicenseAgreementText ', getError(err));
      setIsLoading(false);
      // setError(getError(err));
    });
}

export async function singInUpConfirmationCode(
  values,
  setIsLoading,
  setServerResponse,
) {
  setIsLoading(true);
  const login = {};
  login['login'] = values.login;
  const body = {
    ...login,
    password: values.password,
    confirmationCode: values.confirmationCode,
  };
  await httpClient
    .post(api.url('ConfirmNewAccount'), body, {
      config: { isWrap: false },
    })
    .then(() => {
      setIsLoading(false);
      setServerResponse({ action: 'MAY_LOGIN' });
    })
    .catch(async (err) => {
      //console.log('ERROR singInUpConfirmationCode ==', err, '');
      if (getError(err, 'code') === 'WRONG_CONFIRMATION_CODE')
        setServerResponse({ action: 'WRONG_CONFIRMATION_CODE' });
      else setServerResponse({ action: getError(err) });

      setIsLoading(false);
    });
}

export async function changeEmailConfirmationCode(
  dispatch,
  values,
  user,
) {
  dispatch({
    type: 'SET_USER',
    payload: { isLoaded: false },
  });

  await httpClient
    .post(
      api.url('ConfirmNewEmail'),
      {
        code: values.confirmationCode,
      },
      {
        config: { isWrap: false }, // needed for old api only
      },
    )
    .then(({ data }) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: { action: 'OK', ...data },
          user: { ...user, ...values },
        },
      });

      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...values }),
      );
    })
    .catch((err) => {
      const userOld = JSON.parse(localStorage.getItem('user'));
      const serverResponse = {
        action: getError(err, 'code'),
        message: getError(err),
      };
      dispatch({
        type: 'SET_PROFILE_CODE_WRONG',
        payload: {
          user: {
            email: userOld.email,
          },
          serverResponse,
        },
      });
    });
}
export async function changePhoneConfirmationCode(
  dispatch,
  values,
  user,
) {
  dispatch({
    type: 'SET_USER',
    payload: { isLoaded: false },
  });

  await httpClient
    .post(
      api.url('ConfirmNewPhone'),
      {
        code: values.confirmationCode,
      },
      {
        config: { isWrap: false }, // needed for old api only
      },
    )
    .then(({ data }) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: { action: 'OK', ...data },
          user: { ...user, ...values },
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({ ...user, ...values }),
      );
    })
    .catch((err) => {
      //console.log('= axios ERROR changePhoneConfirmationCode =', err);
      const userOld = JSON.parse(localStorage.getItem('user'));
      const serverResponse = {
        action: getError(err, 'code'),
        message: getError(err),
      };
      dispatch({
        type: 'SET_PROFILE_CODE_WRONG',
        payload: {
          user: {
            phone: userOld.phone,
          },
          serverResponse,
        },
      });
    });
}
export async function deleteUserPhoto(dispatch, user) {
  await httpClient
    .delete(api.url('DeleteUserPhoto'), {
      mmkId: user.mmkId,
      clinicId: user.clinicId,
    })
    .then(({ data }) => {
      if (data) {
        dispatch({
          type: 'SET_USER',
          payload: {
            serverResponse: { action: 'PHOTO_DELETED' },
            isLoaded: true,
            user: { ...user, photoUrl: '', photo: null },
          },
        });
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user, photoUrl: '', photo: null }),
        );
      }
    })
    .catch((err) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: {
            action: getError(err, 'code'),
            message: getError(err),
          },
        },
      });
      console.log('Err DeleteUserPhoto ', getError(err));
    });
}
export async function uploadUserPhoto(
  dispatch,
  userData,
  fileAttachment,
) {
  const authToken = localStorage.getItem('authToken');

  let formData = new FormData();
  const body = {
    appCode: config.APP_CODE,
    authToken,
    clinicId: userData.clinicId,
    mmkId: userData.mmkId,
  };
  //console.log('uploadUserPhoto fileAttachment \n', fileAttachment);
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });
  formData.append('sign', generateSign(body, config.secretKey));
  formData.append('photo', fileAttachment);

  try {
    const { data } = await httpClient.post(
      api.url('UploadUserPhoto'),
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
        config: { isWrap: false },
      },
    );

    if (data) {
      //console.log('SET_USER_PHOTO data', data);
      dispatch({
        type: 'SET_USER_PHOTO',
        payload: {
          serverResponse: { action: 'PHOTO_UPLOAD_OK' },
          isLoaded: true,
          user: { ...userData, photo: data },
        },
      });
      localStorage.setItem(
        'user',
        JSON.stringify({ ...userData, photo: data }),
      );
    }
  } catch (err) {
    dispatch({
      type: 'SET_USER',
      payload: {
        isLoaded: true,
        serverResponse: {
          action: getError(err, 'code'),
          message: getError(err),
        },
      },
    });
    console.log('Err UploadUserPhoto ', err);
  }
}

export async function updateUserInfo(
  dispatch,
  mmkId,
  clinicId,
  userInfo,
  user,
) {
  await dispatch({
    type: 'SET_USER',
    payload: {
      isLoaded: false,
    },
  });

  const body = {
    mmkId,
    clinicId,
    email: userInfo.email,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    middleName: userInfo.middleName,
    gender: userInfo.gender,
    phone: userInfo.phone,
    birthDate: userInfo.birthDate,
    dmsName: userInfo.dmsName,
    dmsNumber: userInfo.dmsNumber,
    dmsEndDate: userInfo.dmsEndDate,
    omsName: userInfo.omsName,
    omsNumber: userInfo.omsNumber,
  };

  await httpClient
    .post(api.url('UpdateUserInfo'), body)
    .then(({ data }) => {
      console.log('----- UpdateUserInfo  -----', data);

      const action =
        !data?.requiresEmailConfirmation &&
        !data?.requiresPhoneConfirmation
          ? 'OK'
          : data?.requiresEmailConfirmation &&
            !data?.requiresPhoneConfirmation
          ? 'CODE_SENT_EMAIL'
          : !data?.requiresEmailConfirmation &&
            data?.requiresPhoneConfirmation
          ? 'CODE_SENT_PHONE'
          : 'ERR';

      if (action === 'OK') {
        dispatch({
          type: 'SET_USER',
          payload: {
            user: { ...user, ...body },
            serverResponse: { action, ...data },
            isLoaded: true,
          },
        });
        localStorage.setItem(
          'user',
          JSON.stringify({ ...user, ...body }),
        );
      } else {
        dispatch({
          type: 'SET_USER',
          payload: {
            serverResponse: { action, ...data },
            isLoaded: true,
          },
        });
      }
    })
    .catch((err) => {
      //console.log('Err updateUserInfo ', err);
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: {
            action: getError(err, 'code'),
            message: getError(err),
          },
          user: { ...user },
        },
      });
    });
}
export async function downloadPhoto({
  setPhotoData,
  photoUrl,
  appCode,
  authToken,
  userDispatch,
  mmkId,
}) {
  setPhotoData({ photo: null, isLoaded: false });

  try {
    const { data } = await axios.get(photoUrl, {
      headers: {
        'app-code': appCode,
        Authorization: `Bearer ${authToken}`,
      },
      responseType: 'blob',
    });

    let photo = '';
    try {
      photo = await getBase64(data);
      photo = photo.split(',')[1];
    } catch (error) {
      console.error(error);
    }

    setPhotoData({ photo, isLoaded: true });
    userDispatch({
      type: 'SET_MMK_LIST_PHOTO',
      payload: { mmkId, photo },
    });
  } catch (err) {
    console.log('ERROR downloadPhoto =', getError(err));
    setPhotoData({ photo: null, isLoaded: true });
  }
}

export async function changeUser({
  dispatch,
  mmkLinkedList,
  mmkId = 'parent',
  clinicId = null,
  withPhoto = true,
  isAnonymousChildrenEnabled = false,
}) {
  dispatch({
    type: 'SET_USER',
    payload: { isLoaded: false },
  });

  await httpClient
    .get(api.url('GetUserInfo'), {
      mmkId,
      clinicId,
      withPhoto,
    })
    .then(async ({ data }) => {
      const user = JSON.parse(localStorage.getItem('user'));

      const payload = !isEmpty(data)
        ? {
            user: {
              ...data,
              mmkId,
              clinicId,
              isAnonymous: data.canDeleteAccount,
              firstName: data.firstName ?? '',
              middleName: data.middleName ?? '',
              lastName:
                data.lastName ??
                nameAnonymous(isAnonymousChildrenEnabled),
            },
            isLoaded: true,
            mmkLinkedList: mmkLinkedList.map((item) => ({
              ...item,
              photo: item.number === mmkId ? data.photo : item.photo,
            })),
          }
        : { user, isLoaded: true };

      dispatch({
        type: 'SET_USER',
        payload,
      });
    })
    .catch((err) => {
      console.log('ERROR changeUser =', getError(err));
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: {
            action: getError(err, 'code'),
            message: getError(err),
          },
        },
      });
    });
}

export async function deleteUser(dispatch) {
  await httpClient
    .delete(api.url('DeleteAccount'))
    .then(({ data }) => {
      //const serverResponse = data === 'OK' ? 'ACCOUNT_DELETED' : data;
      console.log('= axios  DeleteAccount =', data);
      if (data === 'OK')
        dispatch({
          type: 'SET_USER',
          payload: {
            isLoaded: true,
            serverResponse: {
              action: 'ACCOUNT_DELETED',
            },
          },
        });
    })
    .catch((err) => {
      console.log('= axios ERROR DeleteAccount =', getError(err));
      dispatch({
        type: 'SET_USER',
        payload: {
          isLoaded: true,
          serverResponse: {
            action: getError(err, 'code'),
            message: getError(err),
          },
        },
      });
    });
}

export async function createUser(
  values,
  setIsLoading,
  setServerResponse,
  countryCode = 'RU',
) {
  setServerResponse('');
  setIsLoading(true);
  const login = {};
  if (isValidEmail(values.login)) login.email = values.login;
  else if (isValidPhone(values.login, countryCode))
    login.phone = cleanPhoneValue(values.login);

  const body = {
    ...login,
    password: values.password,
    lastName: values.lastName,
    firstName: values.firstName,
    middleName: values.middleName,
    birthDate: values.birthDate,
    gender: values.gender,
    registrationSource: 'web',
  };

  await httpClient
    .post(api.url('RegisterNewUser'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      console.log(
        '--------- request RegisterNewUser--------- \n',
        body,
        '--------- response RegisterNewUser--------- \n',
        data,
      );

      setServerResponse({ ...data, action: 'CONFIRM_CODE_SENT' });
      setIsLoading(false);
    })
    .catch((err) => {
      setServerResponse({ action: getError(err) });
      setIsLoading(false);
    });
}

export async function initPasswordChanging(
  values,
  setIsLoading,
  setServerResponse,
) {
  setServerResponse({});
  setIsLoading(true);
  const body = { login: values.login };

  await httpClient
    .post(api.url('InitPasswordChanging'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      setIsLoading(false);
      setServerResponse({ ...data, action: 'CONFIRM_CODE_SENT' });
    })
    .catch((err) => {
      setServerResponse({ error: getError(err) });
      setIsLoading(false);
    });
}

export async function changePassword(
  values,
  setIsLoading,
  setServerResponse,
) {
  setServerResponse('');
  setIsLoading(true);

  const body = {
    login: values.login,
    confirmationCode: values.confirmationCode,
    newPassword: values.password,
  };

  await httpClient
    .post(api.url('ChangePassword'), body, {
      config: { isWrap: false },
    })
    .then(({ data }) => {
      setIsLoading(false);
      setServerResponse({ action: data });
    })
    .catch((error) => {
      console.log('Err ChangePasswordResult ==', getError(error));
      setServerResponse({
        error: getError(error),
        action: 'WRONG_CONFIRMATION_CODE',
      });
      setIsLoading(false);
    });
}

//  reset auth & localStorage
// localStorage.removeItem('doctor');
// localStorage.removeItem('user');
// localStorage.removeItem('authToken');
// savedEntites.forEach((key) => {
//   localStorage.removeItem(key);
// });

export function clearLocalStorage() {
  localStorage.removeItem('authToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('user');
  localStorage.removeItem('doctor');
  localStorage.removeItem('chatUser');
  localStorage.removeItem('mmkLinkedList');
  savedEntites.forEach((key) => {
    localStorage.removeItem(key);
  });
  localStorage.removeItem('visitData');
}
export async function signOut(
  dispatch = null,
  navigate = null,
  path = '/',
  isRedirect = false,
) {
  try {
    await httpClient.post(api.url('Logout'));
  } catch (error) {
    console.log('ERROR Logout', getError(error));
  }

  clearLocalStorage();
  console.log('\n\n\n\n ===== done SignOut ===== \n\n\n\n ');
  if (dispatch != null) dispatch({ type: 'SIGN_OUT_SUCCESS' });
  if (navigate != null) navigate(path);
  const accessToken = getParam('access_t');
  if (isRedirect || accessToken) window.location.href = path;
}
export async function getRefreshToken(
  authToken,
  refreshToken,
  signOut,
) {
  try {
    const { data } = await httpClient.post(api.url('RefreshToken'), {
      authToken,
      refreshToken,
    });
    localStorage.setItem('authToken', data?.authToken);
    localStorage.setItem('refreshToken', data?.refreshToken);
    window.location.href = '/';
  } catch (error) {
    console.log('ERROR RefreshToken', getError(error));
    signOut(null, null, '/', true);
  }
}

export async function getLinkedMmkList(dispatch, mmkLinkedList) {
  const body = {};
  await httpClient
    .get(api.url('GetLinkedMmkList'), body)
    .then(async ({ data }) => {
      if (mmkLinkedList.length === 1 && !isEmpty(data)) {
        dispatch({
          type: 'SET_MMK_LINKED_LIST',
          payload: [...mmkLinkedList, ...data],
        });
      }
    })
    .catch((err) =>
      console.log('ERROR GetLinkedMmkList =', getError(err)),
    );
}
export async function getDictsData(dispatch) {
  const reqs = [
    {
      name: 'GetFilials',
      body: {
        includeNotVisible: true,
      },
    },
    {
      name: 'GetDoctorSpecializations',
      body: {
        startIndex: 0,
        searchValue: '',
        count: 300,
        clinicId: 0,
        sortOrder: 'preset',
      },
    },
    {
      name: 'GetPromoActions',
      body: {},
    },
    {
      name: 'GetMmkRecordTypes',
      body: {},
    },
    {
      name: 'GetNotificationTypes',
      body: {},
    },
    {
      name: 'AppInfo',
      body: {},
    },
    {
      name: 'CalypsoForms',
      body: {},
    },
  ];

  const totalRequest = reqs.map((req) =>
    httpClient.get(api.url(req.name), req.body),
  );
  try {
    const res = await axios.all(totalRequest);
    const dictData = {
      clinics: ifEmptyArr(res[0].data)
        .filter((it) => it.isVisible)
        .map((item) => ({
          ...item,
          name_old: item.name,
          name: item.title ? item.title : item.name,
        })),
      specs: ifEmptyArr(res[1].data).filter((it) => it?.name != null),
      promoActions: ifEmptyArr(res[2].data),
      mmkRecordTypes: ifEmptyArr(res[3].data),
      notificationsTypes: ifEmptyArr(res[4].data),
      appInfo: appInfoInit(res[5].data),
      calypsoData: ifEmptyArr(res[6].data),
    };
    Object.keys(dictData).forEach((key) => {
      localStorage.setItem(key, JSON.stringify(dictData[key]));
    });
    console.log('\n === SAVE DICT DATA ===\n', dictData);
    dispatch({
      type: 'SET_DICTS',
      payload: dictData,
    });
  } catch (err) {
    dispatch({
      type: 'SET_USER',
      payload: {
        serverResponseDictsError: getError(err),
      },
    });
    console.log('==== error getDictsData ==== \n', getError(err));
  }
}

export async function loginUserData({
  dispatch,
  lang,
  navigate = null,
  callFromLogin = false,
  setError = null,
  setIsLoading = null,
  role = accountRole.patient,
  isAnonymousChildrenEnabled = false,
}) {
  const body = {
    mmkId: 'parent',
    clinicId: 0,
    withPhoto: true,
  };

  await httpClient
    .get(api.url('GetUserInfo'), body)

    .then(async ({ data }) => {
      if (callFromLogin) {
        setError(false);
        setIsLoading(false);
      }

      const clinicId = 0;

      const fio = `${
        data?.lastName ?? nameAnonymous(isAnonymousChildrenEnabled)
      } ${data?.firstName ?? ''} ${data?.middleName ?? ''}`;
      const mmkLinkedList = [
        { clinicId, number: 'parent', name: fio, photo: data.photo },
      ];
      const authToken = localStorage.getItem('authToken');

      const userData = {
        user: !isEmpty(data)
          ? {
              ...data,
              mmkId: 'parent',
              clinicId,
              lastName:
                data.lastName ??
                nameAnonymous(isAnonymousChildrenEnabled),
              firstName: data.firstName ?? '',
              middleName: data.middleName ?? '',
              isAnonymous: data.canDeleteAccount,
              role,
              lang,
            }
          : { lang, role },

        mmkLinkedList,
        isLoaded: true,
        authToken,
      };
      localStorage.setItem('user', JSON.stringify(userData.user));
      console.log('\n === USER DATA ===\n', userData);

      dispatch({
        type: 'LOGIN_PATIENT',
        payload: userData,
      });
      if (!userData.user.isAnonymous || isAnonymousChildrenEnabled)
        getLinkedMmkList(dispatch, mmkLinkedList);
      if (getParam('access_t') != null) {
        //window.location.href = '/';
        navigate('/');
      }
    })
    .catch((err) => {
      console.log('==== error loginUserData ==== \n', getError(err));
      if (callFromLogin) {
        setError(getError(err));
        setIsLoading(false);
      }
    });
}

export async function loginUser(
  dispatch,
  emailOrPhone,
  password,
  navigate,
  setIsLoading,
  setError,
  setValsTab,
  lang,
  isAnonymousChildrenEnabled = false,
) {
  if (emailOrPhone.length > 0 && password.length > 0) {
    setIsLoading(true);

    const login = {};
    login['login'] = emailOrPhone;

    await httpClient
      .post(api.url('ExternalLogin'), {
        ...login,
        password,
      })
      .then(({ data }) => {
        localStorage.setItem('authToken', data?.authToken);
        localStorage.setItem('refreshToken', data?.refreshToken);

        if (data?.isPasswordExpired) {
          setValsTab({
            activeTabId: 2,
            login: emailOrPhone,
            warningNote: 'EXP_PASS',
          });
          setIsLoading(false);
          return;
        }
        if (data?.isTwoFactorAuth) {
          setValsTab({
            activeTabId: 0,
            login: emailOrPhone,
            warningNote: 'CONFIRM_AUTH',
            confirmationCodeInfo: data?.confirmationCodeInfo,
          });
          setIsLoading(false);
          return;
        }
        return data;
      })
      .then((data) => {
        if (!isEmpty(data))
          loginUserData({
            dispatch,
            lang,
            navigate,
            callFromLogin: true,
            setError,
            setIsLoading,
            role: data?.role,
            isAnonymousChildrenEnabled,
          });
      })
      .catch((err) => {
        setError(getError(err));
        console.log('Error ExternalLogin --- \n', getError(err));
        setIsLoading(false);
      });
  } else {
    dispatch({ type: 'LOGIN_FAILURE' });
  }
}

export async function loginEsiaMam(
  dispatch,
  navigate,
  accessToken,
  setIsLoading,
  setError,
  lang,
  isAnonymousChildrenEnabled = false,
) {
  if (accessToken != null) {
    localStorage.setItem('lang', lang);
    await setIsLoading(true);

    await httpClient
      .post(api.url('LoginEsiaMam'), {
        accessToken,
      })
      .then(async ({ data }) => {
        localStorage.setItem('authToken', data?.authToken);
        localStorage.setItem('refreshToken', data?.refreshToken);
        console.log('LoginEsiaMam --- \n', data);
        //return data;
      })
      .then(() => {
        loginUserData({
          dispatch,
          lang,
          navigate,
          callFromLogin: true,
          setError,
          setIsLoading,
          isAnonymousChildrenEnabled,
        });
      })
      .catch(async (err) => {
        await setError(getError(err));
        console.log('Error ExternalLogin --- \n', getError(err));
        await setIsLoading(false);
      });
  } else {
    await dispatch({ type: 'LOGIN_FAILURE' });
  }
}

export const confirmLogin = async (
  dispatch,
  code,
  navigate,
  setIsLoading,
  setError,
  lang,
  isAnonymousChildrenEnabled = false,
) => {
  try {
    setIsLoading(true);
    const { data } = await httpClient.post(api.url('СonfirmLogin'), {
      code,
    });
    console.log('== confirmLogin == ', data);
    localStorage.setItem('authToken', data?.authToken);
    localStorage.setItem('refreshToken', data?.refreshToken);
    if (data?.authToken != null)
      loginUserData({
        dispatch,
        lang,
        navigate,
        callFromLogin: true,
        setError,
        setIsLoading,
        role: data?.role,
        isAnonymousChildrenEnabled,
      });
  } catch (error) {
    setError(getError(error));
    console.log('Error confirmLogin --- \n', getError(error));
    setIsLoading(false);
  }
};

export const getSessions = async (dispatch) => {
  try {
    const { data } = await httpClient.get(api.url('GetSessions'));
    dispatch({
      type: 'FETCH_SESSIONS',
      payload: ifEmptyArr(data),
    });
  } catch (error) {
    dispatch({
      type: 'FETCH_SESSIONS_ERROR',
      payload: getError(error),
    });

    console.log('ERROR GetSessions', getError(error));
  }
};
export const deleteSession = async (dispatch, jti) => {
  try {
    await httpClient.delete(api.url('DeleteSession'), { jti });
    dispatch({
      type: 'DELETE_SESSION',
      payload: jti,
    });
  } catch (error) {
    console.log('ERROR GetSessions', getError(error));
    dispatch({
      type: 'FETCH_SESSIONS_ERROR',
      payload: getError(error),
    });
  }
};

/** --------------------- Operator --------------------------------------- */
export const getChatOperatorInfo = async (setChatInfo) => {
  try {
    const { data } = await httpClient.get(
      api.url('GetChatOperatorInfo'),
    );

    setChatInfo({
      ...data,
    });
  } catch (error) {
    console.log('ERROR getChatOperatorInfo', getError(error));
  }
};

/**---------------- Doctor  ------------------------------- */
export const getClinics = async (dispatch) => {
  await httpClient
    .get(api.url('GetFilials'), {})
    .then(async ({ data }) => {
      await dispatch({
        type: 'SET_CLINICS',
        payload: data,
      });
      //console.log('res', res?.Data);
    })
    .catch(async (err) => {
      console.log('---Error getting clinics--- \n', getError(err));
      await dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: getError(err),
      });
    });
};
export async function authDataDoctor(dispatch, lang, clinicId) {
  dispatch({
    type: 'LOADING',
  });

  const reqs = [
    // {
    //   name: 'GetCalypsoServerParams',
    //   body: {
    //     timeZoneOffset: -60,
    //   },
    // },
    {
      name: 'GetUserInfo',
      body: {
        clinicId,
      },
    },
    {
      name: 'GetMmkRecordTypes',
      body: {},
    },
    {
      name: 'GetDoctorSpecializations',
      body: {
        startIndex: 0,
        searchValue: '',
        count: 1000,
        clinicId,
      },
    },
  ];
  const requests = reqs.map((req) =>
    httpClient.get(api.url(req.name), req.body),
  );

  try {
    const r = await axios.all(requests);

    const resUserInfo = r[0].data;
    const resMmkRecordTypes = r[1].data;
    const resSpec = r[2].data;

    let payload = {};
    if (!isEmpty(r)) {
      // const rr = await axios.post(
      //   `${calypsoUrl}api/mobimed-login`,
      //   {
      //     token: r[0].data?.token,
      //   },
      // );
      // const calypsoToken = rr.data?.token;
      // const calypsoForm = rr.data?.formGuid;

      payload = {
        doctor: {
          data: true,
          // calypsoUrl,
          // calypsoToken,
          // calypsoForm,
        },
        user: {
          ...resUserInfo,
          lang,
          clinicId,
          mmkId: 'parent',
          role: accountRole.doctor,
        },
        mmkRecordTypes: ifEmptyArr(resMmkRecordTypes),
        specs: ifEmptyArr(resSpec),
      };
    }

    dispatch({
      type: 'LOGIN_DOCTOR',
      payload,
    });
  } catch (err) {
    dispatch({
      type: 'SET_SERVER_RESPONSE',
      payload: getError(err),
    });

    //signOut(dispatch, navigate, '/docsign');
    console.log('Err GetCalypsoServerParams ==', err);
  }
}

export async function loginDoctor(
  dispatch,
  loginOrEmail,
  password,
  clinicId,
  medDepId,
  lang = config.defLang,
) {
  if (
    clinicId > 0 &&
    loginOrEmail.length > 0 &&
    password.length > 0
  ) {
    dispatch({
      type: 'LOADING',
    });

    const body = {
      clinicId,
      medDepId,
      password,
    };
    body['login'] = loginOrEmail;
    await httpClient
      .post(api.url('LoginAsDoctor'), body)
      .then(async ({ data }) => {
        const authToken = data?.authToken;
        const refreshToken = data?.refreshToken;

        const login = {};
        login['login'] = loginOrEmail;
        localStorage.setItem('authToken', authToken);
        localStorage.setItem('refreshToken', refreshToken);

        if (medDepId === 0 && isEmpty(data?.medDepList)) {
          dispatch({
            type: 'SET_USER',
            payload: {
              serverResponse: 'WRONG_LOGIN_OR_PASSWORD',
              loading: false,
            },
          });
          return;
        }
        dispatch({
          type: 'SET_USER',
          payload:
            medDepId === 0
              ? {
                  doctor: {
                    ...login,
                    password,
                    clinicId,
                    medDepList: data?.medDepList,
                    isTwoFactorAuth: data?.isTwoFactorAuth ?? true,

                    step: 1,
                  },
                  authToken,
                  loading: false,
                  error: '',
                }
              : {
                  doctor: {
                    clinicId,
                    medDepId,
                    confirmationCodeInfo: data?.confirmationCodeInfo,
                    isTwoFactorAuth: data?.isTwoFactorAuth ?? true,
                    step: data?.isTwoFactorAuth === true ? 2 : 0,
                  },
                  authToken,
                  loading: false,
                  error: '',
                },
        });
        if (medDepId > 0 && !data?.isTwoFactorAuth) {
          authDataDoctor(dispatch, lang, clinicId);
        }
      })
      .catch((err) => {
        console.log('---Error LoginAsDoctor --- \n', err);
        dispatch({
          type: 'SET_SERVER_RESPONSE',
          payload: getError(err),
        });
      });
  } else {
    dispatch({ type: 'LOGIN_DOCTOR_FAILURE' });
  }
}

export async function sendLoginAsDoctorConfirmationCode(
  dispatch,
  code,
  clinicId,
  medDepId,
  lang,
) {
  dispatch({
    type: 'LOADING',
  });
  const body = {
    code,
    clinicId,
    medDepId,
  };

  await httpClient
    .post(api.url('SendLoginAsDoctorConfirmationCode'), body)
    .then(async ({ data }) => {
      const authToken = data?.authToken;
      const refreshToken = data?.refreshToken;

      localStorage.setItem('authToken', authToken);
      localStorage.setItem('refreshToken', refreshToken);

      dispatch({
        type: 'SET_DOC_DATA',
        payload: {
          login: null,
          password: null,
        },
      });

      authDataDoctor(dispatch, lang, clinicId);
    })
    .catch(async (err) => {
      await dispatch({
        type: 'SET_SERVER_RESPONSE',
        payload: getError(err),
      });

      setTimeout(() => {
        dispatch({
          type: 'SET_DOC_DATA',
          payload: { confirmationCodeInfo: null },
        });

        //navigate('/docsign');
      }, 3000);

      console.log('Err SendLoginAsDoctorConfirmationCode ==', err);
    });
}

export const callDoctor = async (dispatch, body, callback) => {
  //console.log('callDoctor', body);
  try {
    const { data } = await httpClient.post(
      api.url('CallDoctor'),
      body,
    );
    if (data != null) {
      dispatch({ isLoaded: true, data, posted: true });
      callback();
    }
  } catch (error) {
    console.log('ERROR callDoctor', getError(error));
    dispatch({
      isLoaded: true,
      posted: true,
      serverError: getError(error),
    });
  }
};

/** ------------------------------------------------------------ */
export const chekGetParamToken = async (
  dispatch,
  lang,
  navigate,
  isAnonymousChildrenEnabled,
) => {
  //const token = localStorage.getItem('authToken');
  const token = getParam('token');
  //console.log('getParam token:', token);
  if (token == null) return;
  if (token != null) {
    const decoded = jwt_decode(token);
    if (decoded == null) signOut(dispatch, navigate);
  }

  try {
    const url = `${config.baseURLApi}auth/tokenData`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    if (data == null) signOut(dispatch, navigate);

    localStorage.setItem('authToken', token);

    if (data?.role === accountRole.patient)
      loginUserData({
        dispatch,
        lang,
        navigate,
        isAnonymousChildrenEnabled,
      });
    if (data?.role === accountRole.doctor)
      authDataDoctor(dispatch, config.defLang, data?.meddepClinicId);

    //localStorage.setItem('chatUser', JSON.stringify(data));
  } catch (error) {
    console.log('ERROR chekToken', getError(error));
    signOut(dispatch, navigate);
  }
};

export const confirmAuth = async (
  dispatch,
  lang,
  setServerResponse,
  token,
  isAnonymousChildrenEnabled = false,
) => {
  try {
    const body = { token };
    const { data } = await httpClient.post(
      api.url('ConfirmAuth'),
      body,
    );
    setServerResponse(data);
    if (data === true) {
      loginUserData({
        dispatch,
        lang,
        isAnonymousChildrenEnabled,
      });
    }
  } catch (error) {
    console.log('ERROR confirmAuth', getError(error));
  }
};
