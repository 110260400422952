import React from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';

//import ScrollToTop from '../components/ScrollToTop';

// components

import { accountRole } from '../actions/user';

// context
import { useUserStateDispatch } from '../context/UserContext';

// routers
import PrivateRoutes from './PrivateRoutes';
import PublicRoutes from './PublicRoutes';
import LayoutOnlyLogo from '../components/Layout/LayoutOnlyLogo';
import InvoicePaymentSuccess from '../pages/invoices/InvoicePaymentSuccess';
import InvoicePaymentError from '../pages/invoices/InvoicePaymentError';

//import Refresh from './Refresh';
export default function AppRoutes() {
  const {
    userState: {
      isAuthenticated,
      isAuthenticatedDoctor,
      user,
      appInfo,
    },
  } = useUserStateDispatch();
  const location = useLocation();

  const role = user?.role || accountRole.patient;
  const authInitRoute =
    isAuthenticatedDoctor ||
    (isAuthenticated && role === accountRole.operator)
      ? '/app/chat'
      : `/app${
          location.pathname.includes('/home') ||
          location.pathname.includes('/visit')
            ? location.pathname.replace('/app')
            : '/home'
        }`;

  const unAuthInitRoute = appInfo.requireStartPageAuth
    ? '/sign'
    : '/home';

  return (
    <Routes>
      <Route
        path="*"
        element={
          <PublicRoutes
            isAuth={isAuthenticated || isAuthenticatedDoctor}
            authInitRoute={authInitRoute}
          />
        }
      />
      <Route
        path="/app/*"
        element={
          <PrivateRoutes
            isAuthenticated={isAuthenticated}
            isAuthenticatedDoctor={isAuthenticatedDoctor}
            role={role}
            unAuthInitRoute={unAuthInitRoute}
          />
        }
      />
      <Route
        path="/"
        element={<Navigate to={authInitRoute} replace />}
      />
      <Route path="/payment" element={<LayoutOnlyLogo />}>
        <Route path="success" element={<InvoicePaymentSuccess />} />
        <Route path="error" element={<InvoicePaymentError />} />
      </Route>
    </Routes>
  );
}
