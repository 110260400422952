import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Modale from '../../../components/Modals/Modale';
import useForm from '../../../hooks/useForm';
import {
  TextField as Input,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Button,
  Typography,
  Grid,
  FormHelperText,
  Switch,
  FormControlLabel,
} from '@mui/material';

import { dateToHL7 } from '../../../helpers/dateFormat';
import {
  MAX_BIRTH_YEARs,
  MIN_BIRTH_YEARs,
  validate,
} from './validationCallDoctor';

import { useUserStateDispatch } from '../../../context/UserContext';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../../helpers';
import { callDoctor } from '../../../actions/user';
import {
  cleanPhoneValue,
  formatPhoneString,
} from '../../../helpers/utils';
import InsuranceCompanyForm from '../../../components/Forms/InsuranceCompanyForm';
import Marked from 'react-markdown';
import MuiUIPicker from '../../../components/MUIDatePicker';
import dayjs from 'dayjs';
import { handlePhoneChange } from '../../../helpers/handlers';

const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    margin: `${theme.spacing(2)} auto`,
    height: theme.spacing(6),
    maxWidth: theme.spacing(44),
  },
  conteiner: {
    maxWidth: 760,
    margin: `0 ${theme.spacing(4)}`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(2)}`,
    },
    display: 'flex',
    alignItems: 'stretch',
  },
  marked: {
    '& p': { margin: theme.spacing(2) },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleCallDoctor({
  modalCallDoc,
  setModalCallDoc,
  setPostCallDoctor,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [okThen, setOkThen] = useState(true);
  const [privateHouse, setPrivateHouse] = useState(false);
  const handleChangePrivateHouse = (event) => {
    // if (event.target.checked) {
    //   delete values.flat;
    //   delete values.entrance;
    //   delete values.floor;
    //   delete values.intercom;
    // }
    setPrivateHouse(event.target.checked);
  };
  const {
    userState: {
      user: {
        birthDate,
        lastName,
        phone,
        firstName,
        middleName,
        dmsName,
        dmsNumber,
        dmsEndDate,
        omsName,
        omsNumber,
        mmkId,
        gender,
      },
      appInfo,
    },
  } = useUserStateDispatch();
  const phoneNumber = phone
    ? formatPhoneString(phone, appInfo.countryCode)
    : '';
  const initVals = {
    ageGroup: 'Adult',
    gender: gender ?? 'M',
    lastName,
    firstName,
    middleName,
    phoneNumber,
    birthDate: birthDate ? dayjs(birthDate) : null,
    callDate: null,
    dmsName,
    dmsNumber,
    dmsEndDate: dmsEndDate ? dayjs(dmsEndDate) : null,
    omsName,
    omsNumber,
  };

  useEffect(() => {
    setValues(initVals);
    // eslint-disable-next-line
  }, [mmkId]);

  const resetForm = () => {
    setValues(initVals);
  };
  const submitCallDoctor = () => {
    setPostCallDoctor({ isLoaded: false, posted: true });
    const insurance = {
      dmsName: values.dmsName,
      dmsNumber: values.dmsNumber,
      dmsEndDate:
        values.dmsEndDate != null
          ? dateToHL7(values.dmsEndDate.toDate())
          : '',

      omsName: values.omsName,
      omsNumber: values.omsNumber,
    };

    delete values.dmsName;
    delete values.dmsNumber;
    delete values.dmsEndDate;
    delete values.omsName;
    delete values.omsNumber;

    const specialMarks = [];
    if (values.hasCovid === 'yes') {
      specialMarks.push(t('CALL_DOCTOR.FORM_YES_COVID19'));
    }
    if (values.hasCovid === 'no') {
      specialMarks.push(t('CALL_DOCTOR.FORM_NO_COVID19'));
    }
    delete values.hasCovid;

    const addressTitles = !privateHouse
      ? {
          city: t('CALL_DOCTOR.FORM_ADDRESS_CITY'),
          street: t('CALL_DOCTOR.FORM_ADDRESS_STREET'),
          house: t('CALL_DOCTOR.FORM_ADDRESS_HOUSE'),
          flat: t('CALL_DOCTOR.FORM_ADDRESS_APPARPMENT'),
          entrance: t('CALL_DOCTOR.FORM_ADDRESS_ENTRANCE'),
          floor: t('CALL_DOCTOR.FORM_ADDRESS_FLOOR'),
          intercom: t('CALL_DOCTOR.FORM_ADDRESS_DOORPHONE'),
        }
      : {
          city: t('CALL_DOCTOR.FORM_ADDRESS_CITY'),
          street: t('CALL_DOCTOR.FORM_ADDRESS_STREET'),
          house: t('CALL_DOCTOR.FORM_ADDRESS_HOUSE'),
        };
    const addressParts = !privateHouse
      ? {
          city: values.city,
          street: values.street,
          house: values.house,
          flat: values.flat,
          entrance: values.entrance,
          floor: values.floor,
          intercom: values.intercom,
        }
      : {
          city: values.city,
          street: values.street,
          house: values.house,
        };
    const address =
      Object.keys(addressParts)
        .map(
          (key) =>
            addressParts[key] &&
            `${addressTitles[key]}: ${values[key]}`,
        )
        .join(', ') + `${privateHouse ? ' - ' : ''}`;

    if (privateHouse) {
      values.comment = `${t('CALL_DOCTOR.PRIVATE_HOUSE')}\n${
        values.comment ?? ''
      }`;
    }

    delete values.city;
    delete values.street;
    delete values.house;
    delete values.flat;
    delete values.entrance;
    delete values.floor;
    delete values.intercom;

    const body = {
      ...values,
      insurance,
      specialMarks,
      address,
      addressParts,
      phoneNumber: cleanPhoneValue(values.phoneNumber),
      birthDate: dateToHL7(values.birthDate.toDate()),
      callDate:
        values.callDate != null
          ? dateToHL7(values.callDate.toDate())
          : null,
    };
    //console.log('body', body);
    callDoctor(setPostCallDoctor, body, resetForm);
    setModalCallDoc(false);
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(submitCallDoctor, validate, appInfo);

  const handleAnyChange = (val) => {
    const vals = {
      ...values,
      ...val,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };
  const handleChangeAgeGroup = (event) => {
    const vals = {
      ...values,
      ageGroup: event.target.value,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };
  const handleChangeHasCovid = (event) => {
    const vals = {
      ...values,
      hasCovid: event.target.value,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };
  const disabled = !privateHouse
    ? !isEmpty(errors) ||
      values.firstName == null ||
      values.lastName == null ||
      values.phoneNumber == null ||
      values.birthDate == null ||
      values.city == null ||
      values.street == null ||
      values.house == null ||
      values.flat == null ||
      values.entrance == null ||
      values.floor == null ||
      values.intercom == null
    : !isEmpty(errors) ||
      values.firstName == null ||
      values.lastName == null ||
      values.phoneNumber == null ||
      values.birthDate == null ||
      values.city == null ||
      values.street == null ||
      values.house == null;

  return (
    <Modale
      open={modalCallDoc}
      toggleModal={() => setModalCallDoc(!modalCallDoc)}
      height={810}
      maxWidth="ld"
    >
      <Typography variant="h3" align="center" mb={4}>
        {t('CALL_DOCTOR.FORM_TITLE')}
      </Typography>
      <div className={classes.conteiner}>
        {!okThen ||
        appInfo.doctorBeforeCallText == null ||
        appInfo.doctorBeforeCallText === '' ? (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {t('MAINMENU.PROFILE')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="lastName"
                variant="outlined"
                autoComplete={'off'}
                value={values.lastName || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_LAST_NAME')}
                type="text"
                fullWidth
                inputProps={{ maxLength: 50 }}
                required
                error={errors?.lastName != null}
                helperText={
                  errors?.lastName != null && errors?.lastName
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="firstName"
                variant="outlined"
                autoComplete={'off'}
                value={values.firstName || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_NAME')}
                type="text"
                fullWidth
                inputProps={{ maxLength: 50 }}
                required
                error={errors?.firstName != null}
                helperText={
                  errors?.firstName != null && errors?.firstName
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="middleName"
                variant="outlined"
                autoComplete={'off'}
                value={values.middleName || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_MIDLE_NAME')}
                type="text"
                fullWidth
                inputProps={{ maxLength: 50 }}
                error={errors?.middleName != null}
                helperText={
                  errors?.middleName != null && errors?.middleName
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <MuiUIPicker
                label={t('COMPONENT.BIRTH_DATE')}
                value={dayjs(values.birthDate)}
                handleChange={(birthDate) => {
                  birthDate =
                    birthDate != null ? dayjs(birthDate) : null;
                  handleAnyChange({ birthDate });
                }}
                defaultValue={dayjs().add(MAX_BIRTH_YEARs, 'years')}
                minDate={dayjs().add(MIN_BIRTH_YEARs, 'years')}
                maxDate={dayjs().add(MAX_BIRTH_YEARs, 'years')}
                required
                errorText={errors?.birthDate}
                margin="none"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Input
                name="phoneNumber"
                variant="outlined"
                value={values.phoneNumber || ''}
                onChange={(e) =>
                  handlePhoneChange(
                    e,
                    values,
                    setValues,
                    setErrors,
                    validate,
                    appInfo,
                  )
                }
                //  disabled={values.oldEmail == null}

                margin="none"
                label={t('COMPONENT.FORM_PHONE')}
                type="text"
                fullWidth
                error={errors?.phoneNumber != null}
                helperText={
                  errors?.phoneNumber != null && errors?.phoneNumber
                }
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t('COMPONENT.FORM_GENDER')}
                </InputLabel>
                <Select
                  //labelId="demo-simple-select-outlined-label"
                  //id="demo-simple-select-outlined"
                  name="gender"
                  value={values.gender != null ? values.gender : 'M'}
                  onChange={handleChangeGender}
                  label={t('COMPONENT.FORM_GENDER')}
                >
                  <MenuItem value="M">
                    {t('COMPONENT.FORM_GENDER_M')}
                  </MenuItem>
                  <MenuItem value="F">
                    {t('COMPONENT.FORM_GENDER_F')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {t('CALL_DOCTOR.ADDRESS')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="city"
                    variant="outlined"
                    value={values.city || ''}
                    onChange={handleChange}
                    label={t('CALL_DOCTOR.FORM_ADDRESS_CITY')}
                    type="text"
                    fullWidth
                    required
                    error={errors?.city != null}
                    helperText={errors?.city != null && errors?.city}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="street"
                    variant="outlined"
                    value={values.street || ''}
                    onChange={handleChange}
                    label={t('CALL_DOCTOR.FORM_ADDRESS_STREET')}
                    type="text"
                    fullWidth
                    required
                    error={errors?.street != null}
                    helperText={
                      errors?.street != null && errors?.street
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="house"
                    variant="outlined"
                    value={values.house || ''}
                    onChange={handleChange}
                    label={t('CALL_DOCTOR.FORM_ADDRESS_HOUSE')}
                    type="text"
                    fullWidth
                    required
                    error={errors?.house != null}
                    helperText={
                      errors?.house != null && errors?.house
                    }
                  />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={privateHouse}
                        onChange={handleChangePrivateHouse}
                      />
                    }
                    label={
                      <Typography variant="body1">
                        {t('CALL_DOCTOR.PRIVATE_HOUSE')}
                      </Typography>
                    }
                  />
                </Grid>
                {!privateHouse && (
                  <>
                    <Grid item xs={6} sm={3}>
                      <Input
                        name="flat"
                        variant="outlined"
                        value={values.flat || ''}
                        onChange={handleChange}
                        label={t(
                          'CALL_DOCTOR.FORM_ADDRESS_APPARPMENT',
                        )}
                        type="number"
                        fullWidth
                        required
                        error={errors?.flat != null}
                        helperText={
                          errors?.flat != null && errors?.flat
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Input
                        name="entrance"
                        variant="outlined"
                        value={values.entrance || ''}
                        onChange={handleChange}
                        label={t('CALL_DOCTOR.FORM_ADDRESS_ENTRANCE')}
                        type="number"
                        fullWidth
                        required
                        error={errors?.entrance != null}
                        helperText={
                          errors?.entrance != null && errors?.entrance
                        }
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Input
                        name="floor"
                        variant="outlined"
                        value={values.floor || ''}
                        onChange={handleChange}
                        label={t('CALL_DOCTOR.FORM_ADDRESS_FLOOR')}
                        type="number"
                        fullWidth
                        required
                        error={errors?.floor != null}
                        helperText={
                          errors?.floor != null && errors?.floor
                        }
                      />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <Input
                        name="intercom"
                        variant="outlined"
                        value={values.intercom || ''}
                        onChange={handleChange}
                        label={t(
                          'CALL_DOCTOR.FORM_ADDRESS_DOORPHONE',
                        )}
                        type="number"
                        fullWidth
                        required
                        error={errors?.intercom != null}
                        helperText={
                          errors?.intercom != null && errors?.intercom
                        }
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center">
                {t('CALL_DOCTOR.INFO_FOR_DOC')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="demo-simple-select-outlined-label">
                  {t('COMPONENT.FORM_AGEGROUP')}
                </InputLabel>
                <Select
                  //labelId="demo-simple-select-outlined-label"
                  //id="demo-simple-select-outlined"
                  name="ageGroup"
                  value={values.ageGroup}
                  onChange={handleChangeAgeGroup}
                  label={t('COMPONENT.FORM_AGEGROUP')}
                  error={errors?.ageGroup != null}
                >
                  {/* <MenuItem value="Any">
                  {t('COMPONENT.FORM_AGEGROUP_TITLE0')}
                </MenuItem> */}
                  <MenuItem value="Adult">
                    {t('COMPONENT.FORM_AGEGROUP_TITLE1')}
                  </MenuItem>
                  <MenuItem value="Child">
                    {t('COMPONENT.FORM_AGEGROUP_TITLE2')}
                  </MenuItem>
                </Select>
                {errors?.ageGroup != null && errors?.ageGroup && (
                  <FormHelperText error>
                    {errors?.ageGroup}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiUIPicker
                label={t('CALL_DOCTOR.FORM_CALL_DATE')}
                value={dayjs(values.callDate)}
                handleChange={(callDate) => {
                  callDate =
                    callDate != null ? dayjs(callDate) : null;
                  handleAnyChange({ callDate });
                }}
                disablePast
                errorText={errors?.callDate}
                margin="none"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                name="reason"
                variant="outlined"
                value={values.reason || ''}
                onChange={handleChange}
                label={t('CALL_DOCTOR.FORM_REASON')}
                type="text"
                multiline
                rows={5.2}
                fullWidth
                required
                error={errors?.reason != null}
                helperText={errors?.reason != null && errors?.reason}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                required
                sx={{ mb: 2 }}
              >
                <InputLabel>
                  {t('CALL_DOCTOR.FORM_HAS_COVID19')}
                </InputLabel>
                <Select
                  name="hasCovid"
                  value={values.hasCovid}
                  onChange={handleChangeHasCovid}
                  label={t('CALL_DOCTOR.FORM_HAS_COVID19')}
                  error={errors?.hasCovid != null}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="yes">
                    {t('CALL_DOCTOR.FORM_YES_COVID19')}
                  </MenuItem>
                  <MenuItem value="no">
                    {t('CALL_DOCTOR.FORM_NO_COVID19')}
                  </MenuItem>
                </Select>
                {errors?.hasCovid != null && errors?.hasCovid && (
                  <FormHelperText error>
                    {errors?.hasCovid}
                  </FormHelperText>
                )}
              </FormControl>

              <Input
                name="comment"
                variant="outlined"
                value={values.comment || ''}
                onChange={handleChange}
                label={t('CALL_DOCTOR.FORM_COMMENTS')}
                type="text"
                multiline
                rows={2}
                fullWidth
              />
            </Grid>
            <InsuranceCompanyForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              handleAnyChange={handleAnyChange}
              isDisable={() => false}
            />
            <Grid item xs={12} textAlign={'center'}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                fullWidth
                disabled={disabled}
              >
                {t('CALL_DOCTOR.BUTTON_CALL_DOCTOR')}
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={9}>
            <Grid item xs={12} className={classes.marked}>
              {appInfo.doctorBeforeCallText && (
                <Marked>{appInfo.doctorBeforeCallText}</Marked>
              )}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              <Button
                className={classes.buttonSubmit}
                variant="contained"
                color="primary"
                onClick={() => {
                  setOkThen(!okThen);
                }}
                fullWidth
              >
                Перейти к форме
                {/* {t('CALL_DOCTOR.BUTTON_OK_THEN')} */}
              </Button>
            </Grid>
          </Grid>
        )}
      </div>
    </Modale>
  );
}
