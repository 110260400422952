import logoHero from '../images/logo_hero_sibgmu.svg';
import { ReactComponent as LogoHeader } from '../images/logo_sibgmu.svg';
import heroImg from '../images/heroImgPatientSibGmu.svg';
import logoOnly from '../images/logo_sibgmu_only.svg';

import alterDone from '../images/appointment_done_sibgmu.svg';

import checkMark from '../images/check_mark_sibgmu.svg';
import mmkNoRecords from '../images/mmk_no_records_sibgmu.svg';
import alterCallDrDone from '../images/call_dr_done_sibgmu.svg';
import { ReactComponent as AppStoreIcon } from '../images/appStore.svg';
import { ReactComponent as GooglePlayIcon } from '../images/googlePlay.svg';
import { ReactComponent as RuStoreIcon } from '../images/ruStoreBlack.svg';
//import call from '../images/call_doc_pimu.svg';
import phone from '../images/call_sibgmu.svg';
import visit from '../images/visit_sibgmu.svg';
import clinic from '../images/clinic_sibgmu.svg';

const custom = {
  defTitle:
    'Клиники Сибирского Государственного Медицинского Университета в Томске',
  AppStoreIcon,
  GooglePlayIcon,
  RuStoreIcon,
  logoHero,
  LogoHeader,
  logoOnly,
  heroImg,

  alterDone,
  alterCallDrDone,
  mmkNoRecords,
  checkMark,

  availableLanguages: 'RU,EN,FR',
  countryCode: 'RU',

  firstButtons: { phone, visit, clinic },
  showLoginDoctorButton: false,
  authMenuItems: [
    'home',
    'events_and_visits',
    'mmk',
    'invoices',
    'map',
    //'calypsoForm',
  ],

  forPet: false,
  paidIsBowserWin: false,
  unAuthMenuItems: ['home', 'map'],
  isSimpleRegistrationForm: false,
  //email | phone | both
};
export default custom;
