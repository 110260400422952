import React from 'react';
import {
  SocketProvider,
  RestProvider,
  ChatProvider,
  ChatPage as ChatViewport,
} from '@pmt/chat';

import { useUserStateDispatch } from '../../context/UserContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Chat = ({ lang, activeGroupId, activeChatUserId }) => {
  const {
    userState: { appInfo },
  } = useUserStateDispatch();
  return (
    <ChatProvider defLang={lang} tokenKey="authToken">
      <RestProvider baseURLApi={appInfo.chatBaseURLApi} pageSize={25}>
        <SocketProvider
          wsUrl={appInfo.chatWsUrl}
          wsPath={appInfo.chatWsPath}
        >
          <ChatViewport
            activeGroupId={activeGroupId}
            activeChatUserId={activeChatUserId}
          />
        </SocketProvider>
      </RestProvider>
    </ChatProvider>
  );
};

export default Chat;
